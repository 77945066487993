// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from "react";

import { Input, TopNavigation } from "@cloudscape-design/components";
import { SegmentConfig } from "../../config"

interface Props {
	config: SegmentConfig;
}

export default function Header({ config }: Props) {

	const title = config.segment.charAt(0).toUpperCase() + config.segment.slice(1);
	const urlParams = new URLSearchParams(window.location.search);
	const type = urlParams.get('type') || "Chat Type";	

	const menuItemChat = (name: string) => {
		return {
			id: name.toLowerCase(),
			text: name,
			href: `?type=${name}`,
		}
	}

	return (
		<TopNavigation
			data-selector="headerNav"
			identity={{
				href: `/${config.segment}/`,
				title: `ACME ${title}`,
				logo: {
					src: `/${config.segment}-logo.png`,
					alt: `ACME ${title}`,
				},
			}}
			utilities={[
				{
					type: "menu-dropdown",
					text: type,
					items: [
						menuItemChat("QBusiness"),
						menuItemChat("Connect"),
					]
				},
				{
					type: "button",
					text: "Help",
					href: "#",
					externalIconAriaLabel: " (opens in a new tab)",
				},
				{
					type: "button",
					iconName: "notification",
					title: "Notifications",
					ariaLabel: "Notifications (unread)",
					badge: true,
					disableUtilityCollapse: false,
				},
				{
					type: "menu-dropdown",
					iconName: "user-profile",
					items: [{ id: "signin", text: "Sign In", href: "#" }],
				},
			]}
			search={
				<Input
					type="search"
					placeholder="Search"
					ariaLabel="Search"
					value=""
					readOnly
				/>
			}
		/>
	);
}
