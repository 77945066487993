export interface SegmentConfig {
	segment: string;
	chatUrl: string;
	connect: ConnectChat;
	hero: Hero;
	pagesRow: PagesRow;
	mediaRow?: MediaRow;
}

interface ConnectChat {
    urlId: string;
    id: string;
    snippetId: string;
}

interface Hero {
	subtitle: string;
	description: string;
}

interface PagesRow {
	title: string;
}

interface MediaRow {
	title: string;
	children: {
		description: string;
	}[];
}

export interface SegmentConfigMap {
	[key: string]: SegmentConfig;
}

const councilConfig: SegmentConfig = {
	segment: "council", 
	chatUrl: "https://zbewon5l.chat.qbusiness.us-east-1.on.aws/",
	connect: {
		urlId: 'd2zasqxhmd6ne4',
		id: '41425555-3852-448d-8711-a11111ee2e07',
		snippetId: 'QVFJREFIajZXQjcyUXcrNFBEZVZpU0tIdzdFSjJTRnF3a2E2WXVITnhwS1FrcWpBd1FFV1JYc1BrcGNweU9Pb1lZUHVwbGFRQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNMTFFR1F3UW5zRE9WejBwcEFnRVFnQ3ZUeU1QemhidTEzbkI5SjNibUNKV3RlNmlNL0kvR2lVVDV4anVEbUp1SmJUTEVRRFVIYnRFUFd2Rno6OmJQV1NtNHFteWVEdEcydUdlY1dZOFFDQlIwQlhOc0xrTk1sdklWYkQwc2hnUXRjTk1iV1hkQzFkU2VXSnFSRFNNcVplNFl1ZlIwZWpWVE1YTjRqaEJneDlwcFBYSDZPMU9WU2JHMEN4K2xkUStqRzVidjc1cEpzM0pqVFpZS1VxVW1IOFhNZ1pycHo1UVRMS21BRTdGUk9ybnpIczh0VT0=',
	},
	hero: {
		subtitle: "The best place to find your council services and information.",
		description: "We're dedicated to simplifying access and empowering you to engage with your neighborhood."
	},
	pagesRow: {
		title: "Popular Services & Information",
	},
	mediaRow: {
		title: "Visiting Us",
		children: [
			{
				description: "How to find us"
			},
			{
				description: "Our support"
			}
		]
	}
}

const airportConfig: SegmentConfig = {
	segment: "airport",
	chatUrl: "https://rsictpj2.chat.qbusiness.us-east-1.on.aws/",
	connect: {
		urlId: 'db08fjupg2abb',
		id: 'b71c84ed-924d-4115-8dc1-76bcba0dab6e',
		snippetId: 'QVFJREFIZ2ZYaENvQWJCb1ZtYmxRNlFMNVJYMlhab3BKY1p0RnRGQ2pJNGhxdnBvemdGaGpzYlNabDYzU25McW90NUNMbm1UQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNelZMUitSdm5BeWF2TER5VkFnRVFnQ3RLWWQ2WDFFYXd6TVdlTlUzZGdOaWhDNHV1WDUwcktlSWE3RkxNQWZuc1dhc1FLdTZ2M3g3ZzF0U2E6OkVoNVpTb2dvRE44OWhleCtIcGtBMlprZUxTVi9iRXFHcCtEZWRIbU1EcW1sVEVsa1FyZGlhVFlNUmZzcktHeTVvN0l2bmo2TldXcW9ESnROZmdjbzF4Q2NCT00ydlNUTzErOW5rTWN6OVZ5UjZGVmlWT2JseGtGeVBPM0tiYWJmVEFsTFZrd2pIVXlJT24vK1B3TU1UdXZYbUhNaXNJaz0=',
	},
	hero: {
		subtitle: "The best place to find your airport services and information.",
		description: "Connecting you to the world with seamless journeys and exceptional service."
	},
	pagesRow: {
		title: "Popular Services & Information",
	},
	mediaRow: {
		title: "Visiting Us",
		children: [
			{
				description: "Terminal map"
			},
			{
				description: "Share your excitement with our social media tag #ACMEAirport"
			}
		]
	}
}

export default {
	default: councilConfig,
	council: councilConfig,
	airport: airportConfig,
} as SegmentConfigMap
