import React, { useState } from "react";
import { ExpandableSection, Spinner, Button } from "@cloudscape-design/components";

export interface ChatProps {
	embedUrl: string;
	embedWidth?: number;
	embedHeight?: number;
	embedOffsetRightPc?: number;
	headerText?: string;
	headerInfo?: string;
}

export default function Chat({
	embedUrl,
	embedWidth = 600,
	embedHeight = 650,
	embedOffsetRightPc = 5,
	headerText = "Chat",
	headerInfo = "Chat with us",
}: ChatProps) {
	const [isExpanded, setIsExpanded] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [isMaximized, setIsMaximized] = useState(false);

	const handleOnChange = (event: {
		detail: {
			expanded: boolean;
		};
	}) => {
		setIsExpanded(event.detail.expanded);
	};

	const handleIframeLoad = () => {
		setIsLoading(false);
	};

	const toggleMaximize = () => {
		setIsMaximized(!isMaximized);
	};

	// Get the page max dimensions
	const pageWidth = window.innerWidth;
	const pageHeight = window.innerHeight;
	const minWidth = 485;

	return (
		<div
			style={isMaximized ? {
				position: "fixed",
				top: "0",
				left: "0",
				right: "0",
				bottom: "0",
				width: "100%",
				height: "100%",
				zIndex: "1000",
			} : {
				position: "fixed",
				bottom: isExpanded ? "-10px" : "-5px",
				right: pageWidth <= minWidth ? "0" : `${embedOffsetRightPc}%`,
				width: pageWidth <= minWidth ? "100%" : "auto",
				zIndex: "1000",
			}}
		>
			<ExpandableSection
				aria-label="Chat section"
				defaultExpanded={false}
				disableContentPaddings
				variant="container"
				headerInfo={headerInfo}
				headerText={headerText}
				onChange={handleOnChange}
				headerActions={
					<Button
						variant="icon"
						iconName={isMaximized ? "close" : "external"}
						onClick={toggleMaximize}
						ariaLabel={isMaximized ? "Minimize chat" : "Maximize chat"}
					/>
				}
			>
				<div style={{ position: "relative" }}>
					{isLoading && (
						<div
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "white",
							}}
						>
							<Spinner size="large" />
						</div>
					)}
					<iframe
						src={embedUrl}
						style={isMaximized ? {
							width: "100%",
							height: "calc(100vh - 44px)", // Account for header height
							border: "none",
							overflow: "hidden",
						} : {
							width: pageWidth <= minWidth ? "100%" : embedWidth,
							height: pageWidth <= minWidth ? pageHeight - 84 : embedHeight,
							border: "none",
							overflow: "hidden",
						}}
						allowFullScreen
						onLoad={handleIframeLoad}
					/>
				</div>
			</ExpandableSection>
		</div>
	);
}
