function initializeConnect(props) {
	const amazonConnect = 'amazon_connect';
	const supportedTypes = [
		'text/plain',
		// 'text/markdown', // Adds formatting bar
		'application/vnd.amazonaws.connect.message.interactive',
		'application/vnd.amazonaws.connect.message.interactive.response'
	];

	let scriptElem;

	scriptElem=document.createElement('script');
	scriptElem.src=`https://${props.urlId}.cloudfront.net/amazon-connect-chat-interface-client.js`;
	scriptElem.async=1;
	scriptElem.id=props.id;
	document.getElementsByTagName('head')[0].appendChild(scriptElem);
	window[amazonConnect] = window[amazonConnect] || function() { 
			(window[amazonConnect].ac = window[amazonConnect].ac || []).push(arguments) 
	};

	
	amazon_connect('snippetId', props.snippetId);
	amazon_connect('supportedMessagingContentTypes', supportedTypes);

	amazon_connect('styles', {
		iconType: 'CHAT',
		openChat: {
			color: '#0972de',
			backgroundColor:'#fff',
		}, 
		closeChat: {
			color: '#0972de',
			backgroundColor: '#fff'
		}
	});
	amazon_connect('customStyles', {
			global: {
				frameWidth: '30vw',
				frameHeight: '90vw',
			},
			header: {
				headerBackgroundColor: '#0f1b2a',
			},
		}
	)
	amazon_connect('customizationObject', {
		header: { 
				dropdown: true, 
				dynamicHeader: true,
		},
		transcript: { 
				hideDisplayNames: true, 
				eventMessages: {
						participantJoined: "",
						participantDisconnect: "",
						participantLeft: "",
						participantIdle: "",
						participantReturned: "",
						chatEnded: "",
				},
				displayIcons: true,
				iconSources: { 
						botMessage: "https://demo-q-for-citizens.philipws.people.aws.dev/council-logo.png",
						// systemMessage: "imageURL",
						// agentMessage: "imageURL",
						// customerMessage: "imageURL",
				},
		},
		composer: {
				disableEmojiPicker: true,
				disableCustomerAttachments: true,
		},
		footer: {
				disabled:true,
				skipCloseChatButton: true,
		}
});

amazon_connect('customDisplayNames', {
		header: {
				headerMessage: "Welcome!",
				logoUrl: "https://demo-q-for-citizens.philipws.people.aws.dev/council-logo.png",
		},
		transcript: {
				systemMessageDisplayName: "Amazon System",
				botMessageDisplayName: "ACME Council",
		},
});
}

export default initializeConnect;